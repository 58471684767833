import React from "react"
import "./layout.scss"
import "bootstrap/dist/css/bootstrap.min.css"
import Sticky from "react-sticky-el"

import Footer from "./Footer"
import Navbar from "../components/Navigation"

const Layout = ({ children }) => {
  return (
    <div>
      <main>
        <Sticky style={{ zIndex: 999 }}>
          <Navbar />
        </Sticky>
        {children}
        <Footer />
      </main>
    </div>
  )
}

export default Layout
