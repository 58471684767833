import React from "react"
import "react-sticky-header/styles.css"
import styled from "styled-components"
import links from "../constants/links"
import { Link } from "gatsby"

const Navigation = () => {
  return (
    <NavigationWrapper>
      <nav className="navbar">
        <div className="nav-center mx-auto">
          <div className="nav-header"></div>
          <ul className="nav-links">
            {links.map((item, index) => {
              return (
                <li key={index}>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              )
            })}
          </ul>
        </div>
      </nav>
    </NavigationWrapper>
  )
}

const NavigationWrapper = styled.nav`
  min-height: 60px;
  border-top: 4px solid #ff8a00;
  background: #343a40;
  ul {
    margin-bottom: 0;
  }
  .nav-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* padding: 1rem 1.25rem; */
  }
  .logo-btn {
    background: transparent;
    border: none;
    outline: none;
  }
  .logo-btn:hover {
    cursor: pointer;
  }
  .logo-icon {
    color: #ff8a00;
    font-size: 1.5rem;
  }

  .nav-links {
    list-style-type: none;
    transition: all 0.3s linear;
    height: 0;
    overflow: hidden;
  }
  .show-nav {
    height: 537px;
  }
  .nav-links a {
    display: block;
    padding: 1rem 1.25rem;
    text-decoration: none;
    text-transform: capitalize;
    color: white;
    transition: all 0.3s linear;
    /* font-weight: bold; */
    letter-spacing: 4px;
  }
  .nav-links a:hover {
    color: #ff8a00;
  }
  .nav-social-links {
    display: none;
  }
  @media screen and (min-width: 576px) {
    .navbar {
      padding: 0 2rem;
    }
    .logo-btn {
    }
  }

  @media screen and (min-width: 992px) {
    .logo-btn {
      display: none;
    }
    .nav-center {
      display: flex;
      justify-content: space-between;
    }
    .nav-links {
      height: auto;
      display: flex;
    }
    .nav-social-links {
      display: flex;
      line-height: 0;
    }
    .nav-social-links a {
      color: #ff8a00;
      margin: 0 0.5rem;
      font-size: 1.2rem;
      transition: all 0.3s linear;
    }
    .nav-social-links a:hover {
      color: black;
      transform: translateY(-5px);
    }
  }
`

export default Navigation
