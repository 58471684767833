import React, { useState } from "react"
import { slide as Menu } from "react-burger-menu"
import { Link } from "gatsby"
import styled from "styled-components"
import links from "../constants/links"

export default props => {
  const [open, setOpen] = useState(false)
  const closeMenu = () => {
    setOpen({ open: false })
  }
  return (
    // Pass on our props
    <Wrapper>
      <Menu isOpen={open} {...props}>
        <Link to="/#" className="mb-4" onClick={closeMenu}></Link>
        <div className="col-12 text-center">
          <h3>Jun Märkl</h3>
        </div>
        <div className="col-12 text-center pt-3">
          <ul>
            {links.map((item, index) => {
              return (
                <li className="py-1 mb-1" key={index}>
                  <Link onClick={closeMenu} to={item.path}>
                    {item.text}
                  </Link>
                </li>
              )
            })}
          </ul>
        </div>
      </Menu>
    </Wrapper>
  )
}

const Wrapper = styled.div``
