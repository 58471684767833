import styled from "styled-components"

const MenuWrapper = styled.div`
  height: 100%;
  a {
    outline: none;
  }
  h3 {
    color: #ff8a00;
  }
  ul {
    list-style-type: none;
    li {
      border-bottom: 1px solid #ff8a00;
      border-radius: 20px;
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: #ff8a00;
          transition: all 0.3s linear;
        }
      }
    }
  }
  .menu-item {
    color: white;
    text-decoration: none;
    &:hover {
      font-size: 16px;
      font-size: 1.3vw;
      transition: all 0.3s linear;
      -webkit-text-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
      -moz-text-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
      text-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    }
  }
  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 19px;
    top: 13px;
    button {
      outline: none;
      -moz-outline-style: none;
    }
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #ff8a00;
    -webkit-box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
  }
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    transition: all 0.3s linear;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
    button {
      outline: none;
    }
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: white;
  }

  /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    min-width: 80% !important;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #343a40;
    padding: 2.5em 0.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: transparent;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: white;
    padding: 0.8em;
  }

  /* Individual item */
  .bm-item {
    display: inline-block;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.7) !important;
  }
`
export default MenuWrapper
