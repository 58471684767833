import React from "react"
import styled from "styled-components"
// import { FaFacebookSquare, FaTwitterSquare, FaInstagram } from "react-icons/fa"
import AniLink from "gatsby-plugin-transition-link/AniLink"

const Footer = () => {
  return (
    <FooterWrapper>
      <div className="d-md-flex justify-content-around">
        <div className="p-2">
          photocredit: Tey Tata Keng
          {/* <FaFacebookSquare
            style={{
              fontSize: "2rem",
              marginRight: "0.5rem",
              background: "transparent",
            }}
          />
          <FaTwitterSquare
            style={{
              fontSize: "2rem",
              marginRight: "0.5rem",
              background: "transparent",
            }}
          />
          <FaInstagram
            style={{
              fontSize: "2rem",
              marginRight: "0.5rem",
              background: "transparent",
            }}
          /> */}
        </div>
        <div className="p-2">
          <AniLink fade to="/impressum">
            Impressum
          </AniLink>{" "}
          |{" "}
          <AniLink fade to="/privacypolicy">
            Privacy policy
          </AniLink>
        </div>
        <div className="p-2">
          <a
            href="https://www.heftigewebseiten.de"
            target="_blank"
            rel="noopener noreferrer"
          >
            &copy;Heftigewebseiten {new Date().getFullYear()}
          </a>
        </div>
      </div>
    </FooterWrapper>
  )
}

const FooterWrapper = styled.footer`
  margin-top: auto;
  background: var(--mainBlack);
  padding: 2rem;
  text-align: center;
  color: var(--mainWhite);
  a {
    text-decoration: none;
    color: var(--mainWhite);
    &:hover {
      color: var(--primaryColor);
      transition: var(--mainTransition);
    }
  }
  @media (min-width: 768px) {
  }
`

export default Footer
