export default [
  {
    path: "/#home",
    text: "Home",
  },
  {
    path: "/#news",
    text: "News",
  },
  {
    path: "/#performances",
    text: "Performances",
  },
  {
    path: "/#recordings",
    text: "Recordings",
  },
  {
    path: "/#press",
    text: "Press",
  },
  {
    path: "/#biography",
    text: "Biography",
  },
  {
    path: "/#photos",
    text: "Photos",
  },
  {
    path: "/#multimedia",
    text: "Multimedia",
  },

  {
    path: "/#contact",
    text: "Contact",
  },
]
